import { observer } from 'mobx-react-lite'
import Banner from '../components/Banner'
import CountryList from '../components/CountryList'
import RootStore from '../store/Root'
import uuid4 from 'uuid4'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

interface HomeProps {
    rootStore: RootStore
}

const Home = ({ rootStore }: HomeProps) => {

    const navigate = useNavigate();

    const { shouldDisableSubmit, addRemoveRound16, addRemoveQF, shouldDisableQF, shouldDisableSf, shouldDisableF, shouldDisableW, addRemoveSF, addRemoveF, addRemoveW, isPresentInR16, isPresentInQF, isPresentInSf, isPresentInF, isPresentInW, setIsSelectionCompleted, all_teams, round_16, quarter_final, semi_final, final, winner, isSelectionCompleted, isFormCompleted } = rootStore;

    const [checksCompleted, setChecksCompleted] = useState(false)

    const handleSubmit = () => {
        if (!shouldDisableSubmit()) {
            setIsSelectionCompleted()
            navigate('/form')
        }
    }

    useEffect(() => {
        if (isFormCompleted) {
            navigate('/goodluck');
        } else if (isSelectionCompleted) {
            navigate('/form')
        } else {
            setChecksCompleted(true);
        }
    }, [isFormCompleted, isSelectionCompleted, navigate]);


    if (!checksCompleted) {
        return null;
    }

    return (
        <>
            <Banner />
            <div className='countries-list-section'>
                <CountryList key={uuid4()} rootStore={rootStore} title={'Round Of 16'} name={'round_16'} countries={all_teams} selectedCountries={round_16} onChange={addRemoveRound16} totalTeams={24} maxSelection={16} isPresent={isPresentInR16} shouldDisable={() => false} />
                <CountryList key={uuid4()} rootStore={rootStore} title={'Quarter Final'} name={'quarter_final'} countries={round_16} selectedCountries={quarter_final} onChange={addRemoveQF} totalTeams={16} maxSelection={8} isPresent={isPresentInQF} shouldDisable={shouldDisableQF} />
                <CountryList key={uuid4()} rootStore={rootStore} title={'Semi Final'} name='semi_final' countries={quarter_final} selectedCountries={semi_final} onChange={addRemoveSF} totalTeams={8} maxSelection={4} isPresent={isPresentInSf} shouldDisable={shouldDisableSf} />
                <CountryList key={uuid4()} rootStore={rootStore} title={'Final'} name='final' countries={semi_final} selectedCountries={final} onChange={addRemoveF} totalTeams={4} maxSelection={2} isPresent={isPresentInF} shouldDisable={shouldDisableF} />
                <CountryList key={uuid4()} rootStore={rootStore} title={'Winner'} name='winner' countries={final} selectedCountries={winner} onChange={addRemoveW} totalTeams={2} maxSelection={1} isPresent={isPresentInW} shouldDisable={shouldDisableW} />
                <div className="bottom-btns">
                    <button className="submit-btn" type="submit" disabled={shouldDisableSubmit()} onClick={handleSubmit} >Submit</button>
                </div>
            </div>
        </>
    )
}

export default observer(Home)